import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import PrivateRoute from "../components/PrivateRoute"
import NewUser from "../components/newUser"
import AllUsers from "../components/allUsers"

const App = (location) => {
  return (
    <Layout pageInfo={{ pageName: "index" }}>
        <Router>
            <PrivateRoute path="/app/newUser" component={NewUser} />
            <PrivateRoute path="/app/allUsers" component={AllUsers} />
        </Router>
    </Layout>
  )
}

export default App