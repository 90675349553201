import React from "react"
import { navigate } from "gatsby"
import { Row, Col, Container, Form, Button } from "react-bootstrap"
import { firestore } from "gatsby-theme-firebase"

import Layout from "./layout"

export default class NewUser extends React.Component {
  state = {
    firstName : '',
    lastName : '',
    phone : '',
    category : '',
    isActive : 'on',
    lists : [],
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSelectChange = event => {
    const target = event.target
    const options = target.options
    const name = target.name
    var value = []
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value)
      }
    }

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    //alert(`test: ${this.state.isActive} ${this.state.category} ${this.state.lists}`)

    var userIsActive = ''
    if (this.state.isActive === 'on') 
      userIsActive = true
    else
      userIsActive = false

    firestore.collection("users").add({
      First : this.state.firstName,
      Last : this.state.lastName,
      Phone : this.state.phone,
      IsActive : userIsActive,
      Type : this.state.category,
    })

    navigate('/admin')
  };

  render() {
    return (
        <Container>
          <Row className="justify-content-center my-3">
              <Col md="6">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group controlId='formFirst'>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type='text' name='firstName' onChange={this.handleInputChange} ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='formLast'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type='text' name='lastName' onChange={this.handleInputChange} ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='formPhone'>
                      <Form.Label>Phone</Form.Label>
                      <Form.Control type='number' name='phone' onChange={this.handleInputChange} ></Form.Control>
                    </Form.Group>

                  <Form.Group controlId='formType'>
                      <Form.Label>Category</Form.Label>
                      <Form.Control type='text' name='category' onChange={this.handleInputChange} ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='formIsActive'>
                      <Form.Label>Is Active</Form.Label>
                      <Form.Check name='isActive' onChange={this.handleInputChange} ></Form.Check>
                    </Form.Group>

                    <Form.Group controlId='formLists'>
                      <Form.Label>Lists</Form.Label>
                      <Form.Control as='select' multiple name='lists' onChange={this.handleSelectChange} >
                        <option value='pitchers' >Pitchers</option>
                        <option value='positionplayers' >Position Players</option>
                        <option value='coaching' >Coaching</option>
                        <option value='medical' >Medical</option>
                        <option value='frontoffice' >Front Office</option>
                        <option value='clubhouse' >Clubhouse</option>
                        <option value='broadcasting' >Broadcasting</option>
                      </Form.Control>
                    </Form.Group>

                  <Button variant="primary" type="submit" >Submit</Button>
                </Form>
              </Col>
          </Row>
        </Container>
    )
  };
}