import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, ListGroup } from "react-bootstrap"
import { firestore, useFirestoreQuery } from "gatsby-theme-firebase";

import Layout from "./layout"

const AllUsers = ({location}) => {
  const [users, isLoading] = useFirestoreQuery(
    firestore.collection("users"),
  );

  return (
    <>
      <Container className="text-center">
        <Row>
          <Col>
            <p>
              Group: All Users
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center my-3">
            <Col md="6">
           {isLoading ? (
            <p>Loading...</p>
          ) : (
            <ListGroup>
                {users.map(user => user &&
                    <ListGroup.Item >
                        {
                          <Link to={`user/`} state={{ userId : `${user._id}` }} >{user.First} {user.Last}</Link>
                        }
                    </ListGroup.Item>)}
            </ListGroup>
                )}
          </Col>
        </Row>
      </Container>
    </>
  )}  

export default AllUsers
