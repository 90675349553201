import React, { Component } from "react"
import { navigate, Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import { useAuth } from "gatsby-theme-firebase"

const PrivateRoute = ({ component: Component, location }) => {
    const { isLoading, isLoggedIn, profile } = useAuth();

    return (
        <>
        { isLoggedIn ? (
            <Component { ...location }/>
        ) : (
            <>
                <Container>
                    <Row className="justify-content-center my-3" >
                        <Col md="6"><p>Please <Link to='/' >login</Link> to access this page.</p>
                        </Col>
                    </Row>
                </Container>
            </>
        )}
        </>
    )
}

export default PrivateRoute